<!-- 个人中心 -->
<template>
  <div id="password">
    <div class="main">
      <div>
        <!-- 头部地理位置和搜索框 -->
        <div class="head">
          <!-- 顶部NavBar 开始 -->
          <van-nav-bar
            :title="$t('bankAccount.queryInfo')"
            :left-text="$t('main.return')"
            left-arrow
            style="background-color: rgba(255,255,255,0);"
            @click-left="onClickLeft"
          />
        </div>
      </div>
      <div class="outboxstyle">
        <div style="padding: 16px 12px;">
          <div style="overflow: hidden;border-radius: 8px;box-shadow: 0 0 6px #dde9f3;">
            <van-search
              v-model="value"
              show-action
              placeholder="请输入银行卡号"
              @search="onSearch"
            >
              <template #action>
                <div @click="onSearch">查询</div>
              </template>
            </van-search>
            <van-cell :title="$t('bankAccount.customerNumber')" :value="form.CstNo" />
            <van-cell :title="$t('bankAccount.currencyType')" :value="form.Ccy" />
            <van-cell :title="$t('bankAccount.cashFlag')" :value="form.CashRmtFlg" />
            <van-cell :title="$t('bankAccount.accountOpenInstitutionCode')" :value="form.AcctOpnInstNo" />
            <van-cell :title="$t('bankAccount.accountOpenName')" :value="form.OpnAcctBnkNm" />
            <van-cell :title="$t('bankAccount.credentialType')" :value="form.IdentTp" />
            <van-cell :title="$t('bankAccount.credentialNumber')" :value="form.IdentNo" />
            <van-cell :title="$t('bankAccount.customerName')" :value="form.CstNm" />
            <van-cell :title="$t('bankAccount.accountBalance')" :value="form.AcctBal1" />
            <van-cell :title="$t('bankAccount.availableBalance')" :value="form.AvlblBal1" />
          </div>
          <div class="bottom">
            <van-button type="info" size="large" class="bottombutton" @click="back">{{ $t('main.return') }}</van-button>
          </div>
        </div>
      </div>
      <!-- 回到顶部按钮 -->
      <v-top />
    </div>
  </div>
</template>
<script>
import { checkOldPasswd, resetPass, findUserInfoByAccountNum } from '@/api/password'
import { queryBankAccountInfo } from '@/api/personCenter'
import store from '@/store'
import { Dialog, Toast } from 'vant'
import Vue from 'vue'
import { Search } from 'vant'
Vue.use(Search)
export default {
  name: 'Password',
  data() {
    return {
      value: '',
      form: {
        CstNo: '--',
        Ccy: '--',
        CashRmtFlg: '--',
        AcctOpnInstNo: '--',
        OpnAcctBnkNm: '--',
        IdentTp: '--',
        IdentNo: '--',
        CstNm: '--',
        AcctBal1: '--',
        AvlblBal1: '--'
      }
    }
  },
  beforeCreate() {
    document.querySelector('body').setAttribute('style', 'background: #F5F5F5')
  },
  mounted() {
  },
  methods: {
    onSearch(val) {
      if (
        this.value === ''
      ) {
        Toast.fail(this.$t('bankAccount.fillAccountNo'))
        return false
      }
      queryBankAccountInfo(this.value).then(res => {
        console.log(res)
        if (res.returncode === 'SUCCESS') {
          this.form.CstNo = res.CstNo
          this.form.Ccy = res.Ccy
          this.form.CashRmtFlg = res.CashRmtFlg
          this.form.AcctOpnInstNo = res.AcctOpnInstNo
          this.form.OpnAcctBnkNm = res.OpnAcctBnkNm
          this.form.IdentTp = res.IdentTp
          this.form.IdentNo = res.IdentNo
          this.form.CstNm = res.CstNm
          this.form.AcctBall = res.AcctBal1
          this.form.AvlblBall = res.AvlblBal1
        } else {
          this.form.CstNo = '--'
          this.form.Ccy = '--'
          this.form.CashRmtFlg = '--'
          this.form.AcctOpnInstNo = '--'
          this.form.OpnAcctBnkNm = '--'
          this.form.IdentTp = '--'
          this.form.IdentNo = '--'
          this.form.CstNm = '--'
          this.form.AcctBal1 = '--'
          this.form.AvlblBal1 = '--'
        }
      })
    },
    back() {
      this.$router.go(-1)
    },
    init() {
      // console.log('store', this.$store.state.settings)
      this.form.accountNum = localStorage.getItem('accountNum')
      this.form.schoolCode = window.localStorage.getItem('schoolcode')
      this.form.dataSource = this.$store.state.settings.dataSource
      findUserInfoByAccountNum(
        this.form.accountNum,
        this.form.schoolCode,
        this.form.dataSource
      ).then(res => {
        // 获取userId
        this.form.userId = res.data.userId
      })
      // console.log(this.form)
    },
    doSubmit() {
      // 判断字符串是否为数字和字母和字符组合
      const re = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[~!@#$%^&\*\+,._\(\)\{\}\\?<>;':"-])[\da-zA-Z~!@#$%^&\*\+,._\(\)\{\}\\?<>;':"-]{6,}$/
      if (
        this.form.oldPass === '' ||
        this.form.newPass === '' ||
        this.form.confirmPass === ''
      ) {
        Toast.fail(this.$t('msg.pwdNull'))
        return false
      }
      if (this.form.newPass !== this.form.confirmPass) {
        Toast.fail(this.$t('msg.pwdSame'))
        return false
      }
      if (this.form.newPass.length < 6 || this.form.newPass.length > 20) {
        Toast.fail(this.$t('msg.pwdLength'))
        return false
      }
      if (!re.test(this.form.newPass)) {
        Toast.fail(this.$t('msg.pwdSize'))
        return false
      }
      const md5oldPass = this.$md5(this.form.oldPass)
      const md5newPass = this.$md5(this.form.newPass)
      // 先校验原密码
      checkOldPasswd(
        this.form.accountNum,
        this.form.schoolCode,
        this.form.dataSource,
        md5oldPass
      ).then(res => {
        console.log(res)
        if (res.messageCode === '0') {
          // 校验成功  继续
          // 修改
          resetPass(this.form.userId, md5newPass).then(res => {
            if (res.messageCode === '0') {
            //  修改成功
              Dialog.alert({
                title: '密码修改成功，请重新登录'
              })
              // 退出
              setTimeout(() => {
                store.dispatch('user/logout').then(() => {
                  location.reload() // 为了重新实例化vue-router对象 避免bug
                })
              }, 1000)
              this.$router.push({ name: '/' })
            } else {
              Dialog.alert({
                title: '修改失败'
              })
            }
          })
        }
      })
      // var param = JSON.parse(JSON.stringify(this.form))

      // console.log(this.form)
      // this.checkOld(param)
      // .then(res => {
      //   console.log(res)
      // Dialog.alert({
      //   title: '密码修改成功，请重新登录!',
      //   type: 'success'
      // })
      //   setTimeout(() => {
      //     store.dispatch('user/logout').then(() => {
      //       location.reload() // 为了重新实例化vue-router对象 避免bug
      //     })
      //   }, 1500)
      //   this.$router.push({ name: '/' })
      // })
      // .catch(err => {
      //   console.log(err)
      //   Dialog.alert({
      //     message: err
      //   }).then(() => {
      // on close
      //   })
      // })
    },
    // 返回
    onClickLeft() {
      this.$router.push({ path: '/personCenter' })
    },
    // 带参数跳转页面
    dealwith(item) {
      this.$router.push({
        path: '/login'
      })
      store.dispatch('user/logout').then(() => {
        location.reload() // 为了重新实例化vue-router对象 避免bug
      })
    }
  }
}
</script>
<style scoped lang="scss">
#password {
  .van-cell {
  line-height: 30px;
  }
  .van-icon__image {
    width: 40px;
    height: 30px;
}
  position: relative;
  background-color: #eff6ff;
  .head {
    height: 84px;
    width: 100%;
    background:linear-gradient(135deg, #3793e5 0%, #4046a6 100%);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}
.outboxstyle {
  // padding: 0px 10px 10px 10px;
  background-color: #F5F5F5;
  border-radius: 10px;
  margin-top: -20px;
  margin-bottom: 100px;
  width: 100%;
  height: 100%;
}
.boxstyle {
  margin: 10px 15px 0 15px;
  background-color: #ffffff;
  border: 1px white solid;
  border-radius: 10px;
  box-shadow: 0px 4px 10px #dde9f3;
  p{
    font-size:16px;
    font-weight:700;
    margin: 20px;
  }
}
.buttontext{
  width:90px;
  border-radius:5px
}
.bottom{
  position: fixed;
  bottom:0;
  left: 0;
  width: 100%;
  background: #ffffff;
  height: 80px;
  margin-top:20px;
}
.bottombutton{
  margin: 20px;
  width:90%;
  height: 40px;
}
.van-cell__value {
  span{
    color: #333333;
  }
}
#totalpay {
  .van-cell__value {
  span{
    color: #3BA4F2;
  }
}
}
</style>
<style>
.van-nav-bar .van-icon {
    color: #ffffff;
}
.van-nav-bar__title {
    color: #ffffff;
}
.van-nav-bar__text {
    color: #ffffff;
}
[class*='van-hairline']::after {
    border: none;
}
.van-nav-bar {
  height: 80px;
}
</style>
<style lang="scss" scoped>
.main{
  margin-bottom:50px;
}
.box{
  width: 86%;
  margin:0 auto;
  padding:0 10%;
  height:120px;
  background:linear-gradient(135deg,rgba(55,147,229,1) 0%,rgba(61,105,199,1) 100%);
  border-radius:12px;
}
.img-box{
width:60px;
height:60px;
border:1px solid #ffffff;
background:#fff;
border-radius:50% ;
margin-top:32px;
}
.text-box{
margin-top:20px;

  p {
    font-size:14px;
    line-height: 30px;
    height: 20px;
    color: rgba(216,235,244,1);
    span{
    color: #FFFFFF;
    }
  }
}
</style>
